// Copyright 2021 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import * as Setting from "../Setting";
import * as MemberBackend from "../backend/MemberBackend";
import * as FileBackend from "../backend/FileBackend";
import i18next from "i18next";
var crypto = require("crypto");

require("inline-attachment/src/inline-attachment");
require("inline-attachment/src/codemirror-4.inline-attachment");

export function attachEditor(editor) {
  /* eslint-disable */ inlineAttachment.editors.codemirror4.attach(editor, {
    allowedTypes: ["*"],
  });
}

// upload file through markdown editor
export function uploadMdFile(addMsg) {
  const stdImageExt = ["png", "jpg", "gif", "jpeg"];
  /* eslint-disable */ inlineAttachment.prototype.onFileUploadResponse = function (fileName, fileUrl) {
    let newValue = this.settings.urlText.replace("file", fileName);
    let fileType = Setting.getFileType(fileName); // find the ext of the file, choosing []() or ![]()
    //let fileIndex = fileName.lastIndexOf(".");
    //let ext = fileName.substr(fileIndex+1);
    //let index = stdImageExt.indexOf(ext);
    if (fileType.fileType === "file") {
      newValue = newValue.substring(1);
    }
    newValue = newValue.replace("{filename}", fileUrl);
    let text = this.editor.getValue().replace(this.lastValue, newValue);
    this.editor.setValue(text);
    this.settings.onFileUploaded.call(this, fileName);
  };

  let uploadStatus = false;

  /* eslint-disable */ inlineAttachment.prototype.uploadFile = function (file) {
    if (file.size > 1024 * 1024 * 6) {
      alert("File size exceeds 6MB");
      return;
    }

    let reader = new FileReader();
    reader.onload = (e) => {
      FileBackend.uploadTopicPic(e.target.result).then((res) => {
        if (res.status == "ok") {
          this.uploadStatus = true;
          this.onFileUploadResponse(res.msg, encodeURI(res.data));
        } else alert("oss error, can't upload picture now.");
      });
    };
    reader.readAsDataURL(file);
  };
}

// upload file through files page
export function uploadFile(file) {
  if (file.size > 1024 * 1024 * 6) {
    alert("File size exceeds 6MB");
    return;
  }

  let fileType = Setting.getFileType(file.name);
  let reader = new FileReader();
  reader.onload = (e) => {
    FileBackend.uploadFile(e.target.result, file.name, fileType.ext).then((res) => {
      if (res.status == "ok") {
        FileBackend.addFileRecord({
          fileName: file.name,
          filePath: "file/" + file.name,
          fileUrl: res.data,
          size: file.size,
        }).then(result => {
          if (result.status == "ok") {
            // alert("upload success");
          } else {
            alert("upload failed");
          }
        })
        window.location.href = "/i";
      } else alert("Uploading failed.");
    });
  };
  reader.readAsDataURL(file);
}
export function myUploadFn(param) {
  const errorFn = (response) => {
    // call the erroFn when the upload process failed.
    param.error({
      msg: i18next.t("node:Adding image failed"),
    });
  };
  const successFn = (mdUrl) => {
    param.success({
      url: mdUrl,
      meta: {
        id: fileName,
        title: originalFileName,
        alt: originalFileName,
      },
    });
  };

  let timestamp,
    fileName,
    size,
    originalFileName,
    uploadStatus = false;

  timestamp = Date.parse(new Date());

  let fileType = Setting.getFileType(param.file.name);
  size = param.file.size;
  fileName = timestamp + "." + fileType.ext;

  let reader = new FileReader();
  reader.onload = (e) => {
    FileBackend.uploadFile(e.target.result, timestamp, fileType.ext).then((res) => {
      if (res.status == "ok") {
        this.uploadStatus = true;
        successFn(res.data);
      } else {
        errorFn();
      }
    });
  };
  reader.readAsDataURL(param.file);
}

//上传附件，限制大小为6M
export function uploadAttachFile(file, success, error, meta) {
  if (file.size > 1024 * 1024 * 6) {
    alert("File size exceeds 6MB");
    return;
  }
  console.log("meta", meta);
  const errorFn = (response, meta) => {
    error({
      msg: response.msg, //i18next.t("node:Adding image failed"),
      meta,
    });
  };
  const successFn = (fileid, _meta) => {
    success({
      id: fileid,
      meta,
    });
  };

  let timestamp,
    fileName,
    size,
    originalFileName,
    uploadStatus = false;

  timestamp = Date.parse(new Date());

  let fileType = Setting.getFileType(file.name);
  size = file.size;
  fileName = timestamp + "." + fileType.ext;

  let reader = new FileReader();
  reader.onload = (e) => {
    FileBackend.uploadFile(e.target.result, file.name, fileType.ext).then((res) => {
      if (res.status == "ok") {
        FileBackend.addFileRecord({
          fileName: file.name,
          filePath: "file/" + file.name,
          fileUrl: res.data,
          size: file.size,
          desc: meta.data.descript
        }).then((result) => {
          if (result.status == "ok") {
            uploadStatus = true;
            successFn(result.data, meta);
          } else {
            errorFn(result, meta);
          }
        });
      } else alert("Uploading failed.");
    });

    // FileBackend.uploadFile(e.target.result, timestamp, fileType.ext).then(
    //     (res) => {
    //         if (res.status == "ok") {
    //             uploadStatus = true;
    //             successFn(res.data, meta);
    //         } else {
    //             errorFn(res, meta);
    //         }
    //     }
    // );
  };
  reader.readAsDataURL(file);
}
/**
 * convert number to coin.
 * @param {number} score
 */
export function scoreConverter(score) {
  const rate = 100;
  const goldCount = Math.floor(Math.floor(score / rate) / rate);
  const silverCount = Math.floor(score / rate) % rate;
  const bronzeCount = score % rate;
  return { goldCount, silverCount, bronzeCount };
}

//获取输入信息的sha1
export function getSha1(str) {
  var sha1 = crypto.createHash("sha1");
  sha1.update(str);
  return sha1.digest("hex");
}

//json字符串转换为array
export function jsonToArray(jsonStr) {
  let json = JSON.parse(jsonStr);
  let arr = [];
  for (let key in json) {
    arr.push(json[key]);
  }
  return arr;
}
