// Copyright 2021 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import * as ConfBackend from "./backend/ConfBackend.js"

export const AuthConfig = {
  // serverUrl: "https://door.casbin.com",
  serverUrl: "https://door.bbs.mfull.cn",
  clientId: "b1f74e40ad2bcedd8a43",
  organizationName: "organization_papers_bbs",
  appName: "app_papers_bbs",
  redirectPath: "/callback",
}

export let FrontConfig = {
  forumName: "Paper BBS",
  logoImage: "https://ipfs2.mfinfo.cn/ipfs/bafkreiewaqt7rd7r526u5sr4r4izaakn2ztdo2yvb6xi3fwmrlu4js3dxi",
  footerLogoImage: "https://ipfs2.mfinfo.cn/ipfs/bafkreifnponi7ruyhrmc2dz6jvpqnfoua7gfpwdqopx2x5kpugafjct6uy",
  footerLogoUrl: "https://ipfs2.mfinfo.cn/ipfs/bafkreifnponi7ruyhrmc2dz6jvpqnfoua7gfpwdqopx2x5kpugafjct6uy",
  signinBoxStrong: "Welcone to Paper BBS",
  signinBoxSpan: "A place for sharing papers",
  footerDeclaration: "Word is power by Papers",
  footerAdvise: "",
}

export const ShowEmbedButtons = false

export const ShowGithubCorner = false
export const GithubRepo = "https://github.com/casbin/casnode"

export const Domain = "bbs.mfull.cn"

export const ForceLanguage = ""
export const DefaultLanguage = "en"

// Support: richtext | markdown
export const DefaultEditorType = "markdown"

export const DefaultNodeServer = "https://bbs.mfull.cn"
//Default search engine
//Support: baidu(www.baidu.com) | google(www.google.com) | cn-bing(cn.bing.com)
export const DefaultSearchSite = "cn-bing"

export const EnableNotificationAutoUpdate = true

export const NotificationAutoUpdatePeriod = 10 // second

export const DefaultTopicPageReplyNum = 100

export const ReplyMaxDepth = 10
export const ReplyMobileMaxDepth = 3

export const AvatarAnonymousUrl = "https://ipfs2.mfinfo.cn/ipfs/QmRTMySvLqfR8y6XpFby4PusGjZDTpE8DceQtHFuEuWBrQ?filename=anonymous.png"
export const AvatarErrorUrl = "https://ipfs2.mfinfo.cn/ipfs/QmW3NiZEypCdUSNoWwwaYH4vdUvDZX5qfo49jpyAyEwayv?filename=error.png"

export const ShowLoadingIndicator = true

////ckb MAINNET
export const CKB_NODE_URL = "https://mainnet.rostra.xyz/rpc"
export const CKB_INDEXER_URL = "https://mainnet.rostra.xyz/indexer"
export const COTA_REGISTRY_URL = "https://cota-registry-aggregator.rostra.xyz"
export const COTA_URL = "https://cota-aggregator.rostra.xyz"
////ckb TESTNET
export const CKB_TESTNET_NODE_URL = "https://testnet.ckb.dev/rpc"
export const CKB_TESTNET_INDEXER_URL = "https://testnet.ckb.dev/indexer"
export const COTA_TESTNET_REGISTRY_URL = "https://cota.nervina.dev/registry-aggregator"
export const COTA_TESTNET_URL = "https://cota.nervina.dev/aggregator"

export const CKB_MINT_NFT_URL = "http://127.0.0.1:8001"
export const CKB_IS_MAINNET = false
export let NervosConfig = {
  network: "mainnet",
  serverUrl: "http://127.0.0.1:8001",
  mintNftUrl: "http://127.0.0.1:8001",
  getAddressUrl: "http://127.0.0.1:8001/get_address",

  networkUrl: "https://mainnet.ckbapp.dev",

  explorerUrl: CKB_IS_MAINNET ? "https://pudge.explorer.nervos.org/" : "https://explorer.nervos.org/aggron",
}
